export default {
	properties: [
		{
			text: 'N°',
			key: 'num',
			type: 'text',
			value: '',
			show: true,
			not_show_on_form: true,
			use_to_filter_in_search: true,
		},
		{
			text: 'Nombre',
			key: 'name',
			type: 'text',
			value: '',
			is_title: true,
		},
		{
			text: 'Cuit',
			key: 'cuit',
			type: 'text',
			value: '',
			show: true,
			use_to_check_if_is_repeat: true,
		},
		{
			text: 'Direccion',
			key: 'address',
			type: 'text',
			value: '',
			show: true,
		},
		{
			text: 'Observaciones',
			key: 'observations',
			type: 'textarea',
			value: '',
			show: true,
		},
	],
	singular_model_name_spanish: 'Proveedor',
	plural_model_name_spanish: 'Proveedores',
	create_model_name_spanish: 'Nuevo proveedor',
	text_delete: 'el',
}